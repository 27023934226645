"use client";

import React, { useState, useEffect, useContext } from "react";
import { SearchOutlined, AudioOutlined, SettingOutlined, LaptopOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Modal, Input } from "antd";
import useFetch from "@/hooks/useFetch";
import { getGlobalSearch, getSystems } from "@/app/apiCalls/projects/projectsCRUD";
import { Systems } from "@condoit-apps/database";
import { useDebounce } from "@uidotdev/usehooks";
import { CurrentUserContext } from "@/app/contexts/currentUserContext";
import { Company } from "@condoit-apps/services/src/data/CompanyData.service";
import Link from "next/link";
import { BiSitemap } from "react-icons/bi";
import { SearchResultItem } from "./SearchResultItem";
import { BriefcaseIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/navigation";
import SuperAdminOnly from "../SuperAdminOnly";

export default function GlobalSearch() {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const { currentCompany, isSuperAdmin } = useContext(CurrentUserContext);
  const {
    data: globalSearch,
    callFetch,
    loading,
    setData: setGlobalSearch,
  } = useFetch<{ systems: Systems[]; companies: Company[] }>(getGlobalSearch, {
    fetchOnLoad: false,
  });
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const router = useRouter();

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isSuperAdmin) return;
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setIsOpen((prev) => !prev);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        document.getElementById("global-search-input")?.focus();
      }, 300);
    }
  }, [isOpen]);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      callFetch(`search=${debouncedSearchTerm}&limit=10`);
    } else if (debouncedSearchTerm.length === 0) {
      setGlobalSearch(null);
    }
  }, [debouncedSearchTerm]);

  const handleResultClick = () => {
    setIsOpen(false);
    // setSearchTerm("");
  };

  const getResultUrl = (index: number) => {
    const systems = globalSearch?.systems || [];
    const companies = isSuperAdmin ? globalSearch?.companies || [] : [];

    if (index < systems.length) {
      return isSuperAdmin
        ? `/admin/company/${systems[index].companyId}/projects/${systems[index].id}`
        : `/projects/${systems[index].id}`;
    } else {
      const companyIndex = index - systems.length;
      return `/admin/company/${companies[companyIndex].id}/projects`;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const results = [...(globalSearch?.systems || []), ...(isSuperAdmin ? globalSearch?.companies || [] : [])];

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setFocusedIndex((prev) => {
          const next = prev + 1;
          return next >= results.length ? 0 : next;
        });
        break;
      case "ArrowUp":
        e.preventDefault();
        setFocusedIndex((prev) => {
          const next = prev - 1;
          return next < 0 ? results.length - 1 : next;
        });
        break;
      case "Enter":
        if (focusedIndex >= 0 && focusedIndex < results.length) {
          e.preventDefault();
          const url = getResultUrl(focusedIndex);
          handleResultClick();
          router.push(url);
        }
        break;
    }
  };

  // Reset focused index when search results change or modal closes
  useEffect(() => {
    setFocusedIndex(-1);
  }, [globalSearch, isOpen]);

  return (
    <div>
      <Button
        icon={<SearchOutlined />}
        onClick={() => setIsOpen(true)}
        className="w-full justify-start text-left font-normal bg-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600"
      >
        <span>Search...</span>
      </Button>

      <Modal
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width={600}
        className="top-16"
        style={{ padding: 0 }}
      >
        <div className="flex items-center px-3">
          <Input
            id="global-search-input"
            prefix={
              loading ? <LoadingOutlined className="text-gray-400" /> : <SearchOutlined className="text-gray-400" />
            }
            variant="outlined"
            placeholder="Search..."
            className="flex-1 h-11 text-base mr-3"
            autoFocus={true}
            allowClear
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
            value={searchTerm}
          />
        </div>
        <div className="flex flex-col space-y-4 p-4">
          {searchTerm?.length === 0 ? (
            <>
              <SearchResultItem
                href="#"
                icon={<BiSitemap className="text-lg" />}
                label="Search Systems"
                onClick={handleResultClick}
                isFocused={focusedIndex === 0}
              />
              <SearchResultItem
                href="#"
                icon={<BriefcaseIcon className="h-6 w-6" />}
                label="Search Companies"
                onClick={handleResultClick}
                isFocused={focusedIndex === 1}
              />
            </>
          ) : (
            <>
              {globalSearch?.systems?.map((system, index) => (
                <SearchResultItem
                  key={system.id}
                  href={
                    isSuperAdmin
                      ? `/admin/company/${system?.companyId}/projects/${system.id}`
                      : `/projects/${system.id}`
                  }
                  icon={<BiSitemap className="text-lg" />}
                  label={system.name!}
                  subLabel={`${system.address1}, ${system.city}, ${system.state} ${system.postalCode}`}
                  onClick={handleResultClick}
                  isFocused={focusedIndex === index}
                />
              ))}
              <SuperAdminOnly>
                <>
                  {globalSearch?.companies?.map((company, index) => (
                    <SearchResultItem
                      key={company.id}
                      href={`/admin/company/${company.id}/projects`}
                      icon={<BriefcaseIcon className="h-6 w-6" />}
                      label={company.name!}
                      onClick={handleResultClick}
                      isFocused={focusedIndex === index + (globalSearch?.systems?.length || 0)}
                    />
                  ))}
                </>
              </SuperAdminOnly>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}
